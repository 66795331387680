import * as Sentry from '@sentry/nextjs';

import abyssinianOil from 'assets/images/ingredients/abyssinian-oil.png';
import acaciaCollagen from 'assets/images/ingredients/acacia-collagen.png';
import acai from 'assets/images/ingredients/acai.png';
import acaiOil from 'assets/images/ingredients/acai-oil.png';
import acerola from 'assets/images/ingredients/acerola.png';
import agastache from 'assets/images/ingredients/agastache.png';
import aloeVeraJuice from 'assets/images/ingredients/aloe-vera-juice.png';
import alphaGlucanOligosaccharide from 'assets/images/ingredients/alpha-glucan-oligosaccharide.png';
import aminoAcid from 'assets/images/ingredients/amino-acid.png';
import antiBrassiness from 'assets/images/ingredients/anti-brassiness.png';
import appleCiderVinegar from 'assets/images/ingredients/apple-cider-vinegar.png';
import argan from 'assets/images/ingredients/argan.png';
import arganOil from 'assets/images/ingredients/argan-oil.png';
import arganOilV2 from 'assets/images/ingredients/argan-v2.png';
import arginine from 'assets/images/ingredients/arginine.png';
import avocado from 'assets/images/ingredients/avocado.png';
import avocadoOil from 'assets/images/ingredients/avocado-oil.png';
import babassu from 'assets/images/ingredients/babassu.png';
import babassuOil from 'assets/images/ingredients/babassu-oil.png';
import bakuchiol from 'assets/images/ingredients/bakuchiol.png';
import baobabExtract from 'assets/images/ingredients/baobab-extract.png';
import barbaryFigGrapeseedOil from 'assets/images/ingredients/barbary-fig-grapeseed-oil.png';
import barbaryFigOil from 'assets/images/ingredients/barbary-fig-oil.png';
import basicRed51 from 'assets/images/ingredients/basic-red-51.png';
import beechOil from 'assets/images/ingredients/beech-oil.png';
import binchotanCharcoal from 'assets/images/ingredients/binchotan-charcoal.png';
import biome from 'assets/images/ingredients/biome.png';
import biotin from 'assets/images/ingredients/biotin.png';
import birchTreeExtract from 'assets/images/ingredients/birch-tree-extract.png';
import bitterAlmondOil from 'assets/images/ingredients/bitter-almond-oil.png';
import blackCurrantOil from 'assets/images/ingredients/black-currant-oil.png';
import blackcurrantOil from 'assets/images/ingredients/blackcurrant-oil.png';
import brewersYeast from 'assets/images/ingredients/brewers-yeast.png';
import bronze from 'assets/images/ingredients/bronze.png';
import bushClover from 'assets/images/ingredients/bush-clover.png';
import butterflyPeaExtract from 'assets/images/ingredients/butterfly-pea-extract.png';
import butterflyPeaFlower from 'assets/images/ingredients/butterfly-pea-flower.png';
import californiaWildGrape from 'assets/images/ingredients/california-wild-grape.png';
import calmingSoothing from 'assets/images/ingredients/calming-soothing.png';
import camelinaOil from 'assets/images/ingredients/camelina-oil.png';
import cannabidiol from 'assets/images/ingredients/cannabidiol.png';
import carobSeedExtract from 'assets/images/ingredients/carob-seed-extract.png';
import castor from 'assets/images/ingredients/castor.png';
import castorOil from 'assets/images/ingredients/castor-oil.png';
import caviarLimeExtract from 'assets/images/ingredients/caviar-lime-extract.png';
import ceramides from 'assets/images/ingredients/ceramides.png';
import charcoalPowder from 'assets/images/ingredients/charcoal-powder.png';
import chiaOil from 'assets/images/ingredients/chia-oil.png';
import chicoryCamelinaOil from 'assets/images/ingredients/chicory-camelina-oil.png';
import chicoryOil from 'assets/images/ingredients/chicory-oil.png';
import coconutOil from 'assets/images/ingredients/coconut-oil.png';
import colorProtect from 'assets/images/ingredients/color-protect.png';
import copaibaOil from 'assets/images/ingredients/copaiba-oil.png';
import corianderOil from 'assets/images/ingredients/coriander-oil.png';
import cornflower from 'assets/images/ingredients/cornflower.png';
import coss from 'assets/images/ingredients/coss.png';
import cucumber from 'assets/images/ingredients/cucumber.png';
import cucumberOil from 'assets/images/ingredients/cucumber-oil.png';
import curling from 'assets/images/ingredients/curling.png';
import dandruff from 'assets/images/ingredients/dandruff.png';
import dandruffPowder from 'assets/images/ingredients/dandruff-powder.png';
import defaultOil from 'assets/images/ingredients/default-oil.png';
import detox from 'assets/images/ingredients/detox.png';
import detoxPowder from 'assets/images/ingredients/detox-powder.png';
import dextrin from 'assets/images/ingredients/dextrin.png';
import dipotassiumGlycyrrhizate from 'assets/images/ingredients/dipotassium-glycyrrhizate.png';
import dogRose from 'assets/images/ingredients/dog-rose.png';
import essentialOils from 'assets/images/ingredients/essential-oils.png';
import eucalyptusOil from 'assets/images/ingredients/eucalyptus-oil.png';
import fermentedRiceWater from 'assets/images/ingredients/fermented-rice-water.png';
import fermentedZinc from 'assets/images/ingredients/fermented-zinc.png';
import flaxSeedExtract from 'assets/images/ingredients/flax-seed-extract.png';
import folate from 'assets/images/ingredients/folate.png';
import freshnessPowder from 'assets/images/ingredients/freshness-powder.png';
import gold from 'assets/images/ingredients/gold.png';
import grapeSeedExtract from 'assets/images/ingredients/grape-seed-extract.png';
import grapeSeedOil from 'assets/images/ingredients/grape-seed-oil.png';
import grapefruitOil from 'assets/images/ingredients/grapefruit-oil.png';
import grapeseedOil from 'assets/images/ingredients/grapeseed-oil.png';
import greenTea from 'assets/images/ingredients/green-tea.png';
import grenade from 'assets/images/ingredients/grenade.png';
import hairLoss from 'assets/images/ingredients/hair-loss.png';
import hazelnut from 'assets/images/ingredients/hazelnut.png';
import hazelnutOil from 'assets/images/ingredients/hazelnut-oil.png';
import hcBlue15 from 'assets/images/ingredients/hc-blue-15.png';
import heatProtect from 'assets/images/ingredients/heat-protect.png';
import heatProtectV1 from 'assets/images/ingredients/heat-protect-v1.png';
import hibiscus from 'assets/images/ingredients/hibiscus.png';
import hibiscusFlower from 'assets/images/ingredients/hibiscus-flower.png';
import honey from 'assets/images/ingredients/honey.png';
import horehoundExtract from 'assets/images/ingredients/horehound-extract.png';
import horsetail from 'assets/images/ingredients/horsetail.png';
import hyaluronic from 'assets/images/ingredients/hyaluronic.png';
import hyaluronicAcid from 'assets/images/ingredients/hyaluronic-acid.png';
import illipeButter from 'assets/images/ingredients/illipe-butter.png';
import incaInchiOil from 'assets/images/ingredients/inca-inchi-oil.png';
import intenseRepair from 'assets/images/ingredients/intense-repair.png';
import ivyExtract from 'assets/images/ingredients/ivy-extract.png';
import jasminWater from 'assets/images/ingredients/jasmin-water.png';
import jojobaBeads from 'assets/images/ingredients/jojoba-beads.png';
import jojobaOil from 'assets/images/ingredients/jojoba-oil.png';
import jujubeBarkExtract from 'assets/images/ingredients/jujube-bark-extract.png';
import kalahariMelonOil from 'assets/images/ingredients/kalahari-melon-oil.png';
import kalahariOil from 'assets/images/ingredients/kalahari-oil.png';
import kaleExtract from 'assets/images/ingredients/kale-extract.png';
import karanjaOil from 'assets/images/ingredients/karanja-oil.png';
import kombucha from 'assets/images/ingredients/kombucha.png';
import lemonButter from 'assets/images/ingredients/lemon-butter.png';
import lila from 'assets/images/ingredients/lila.png';
import macaRoot from 'assets/images/ingredients/maca-root.png';
import maltodextrin from 'assets/images/ingredients/maltodextrin.png';
import mangoButter from 'assets/images/ingredients/mango-butter.png';
import mangoLeaf from 'assets/images/ingredients/mango-leaf.png';
import maracuja from 'assets/images/ingredients/maracuja.png';
import maracujaOil from 'assets/images/ingredients/maracuja-oil.png';
import melonOil from 'assets/images/ingredients/melon-oil.png';
import mentha from 'assets/images/ingredients/mentha.png';
import menthol from 'assets/images/ingredients/menthol.png';
import methionine from 'assets/images/ingredients/methionine.png';
import millet from 'assets/images/ingredients/millet.png';
import modifiedCornStarch from 'assets/images/ingredients/modified-corn-starch.png';
import nasturtiumExtract from 'assets/images/ingredients/nasturtium-extract.png';
import niacin from 'assets/images/ingredients/niacin.png';
import niacinamide from 'assets/images/ingredients/niacinamide.png';
import nutrition from 'assets/images/ingredients/nutrition.png';
import oatFlour from 'assets/images/ingredients/oat-flour.png';
import oatOil from 'assets/images/ingredients/oat-oil.png';
import pantothenicAcid from 'assets/images/ingredients/pantothenic-acid.png';
import peaProtein from 'assets/images/ingredients/pea-protein.png';
import peppermintExtract from 'assets/images/ingredients/peppermint-extract.png';
import pequi from 'assets/images/ingredients/pequi.png';
import pequiOil from 'assets/images/ingredients/pequi-oil.png';
import pinkClay from 'assets/images/ingredients/pink-clay.png';
import pistachioOil from 'assets/images/ingredients/pistachio-oil.png';
import plum from 'assets/images/ingredients/plum.png';
import plumOil from 'assets/images/ingredients/plum-oil.png';
import poppy from 'assets/images/ingredients/poppy.png';
import poppyOil from 'assets/images/ingredients/poppy-oil.png';
import porphyraAlgae from 'assets/images/ingredients/porphyra-algae.png';
import pureBiotin from 'assets/images/ingredients/pure-biotin.png';
import quinoaExtract from 'assets/images/ingredients/quinoa-extract.png';
import raspberry from 'assets/images/ingredients/raspberry.png';
import raspberryOil from 'assets/images/ingredients/raspberry-oil.png';
import redAlgae from 'assets/images/ingredients/red-algae.png';
import redFungi from 'assets/images/ingredients/red-fungi.png';
import reedExtracts from 'assets/images/ingredients/reed-extracts.png';
import riboflavin from 'assets/images/ingredients/riboflavin.png';
import riceBran from 'assets/images/ingredients/rice-bran.png';
import riceBranOil from 'assets/images/ingredients/rice-bran-oil.png';
import riceProtein from 'assets/images/ingredients/rice-protein.png';
import riceStarch from 'assets/images/ingredients/rice-starch.png';
import roseHipOil from 'assets/images/ingredients/rose-hip-oil.png';
import rosehipOil from 'assets/images/ingredients/rosehip-oil.png';
import rosemary from 'assets/images/ingredients/rosemary.png';
import sachaInchi from 'assets/images/ingredients/sacha-inchi.png';
import sachaInchiOil from 'assets/images/ingredients/sacha-inchi-oil.png';
import saffron from 'assets/images/ingredients/saffron.png';
import seaLily from 'assets/images/ingredients/sea-lily.png';
import sebum from 'assets/images/ingredients/sebum.png';
import sebumPowder from 'assets/images/ingredients/sebum-powder.png';
import sensitivityPowder from 'assets/images/ingredients/sensitivity-powder.png';
import sesameSeeds from 'assets/images/ingredients/sesame-seeds.png';
import sheaButter from 'assets/images/ingredients/shea-butter.png';
import sheaOil from 'assets/images/ingredients/shea-oil.png';
import shikakaiExtract from 'assets/images/ingredients/shikakai-extract.png';
import shine from 'assets/images/ingredients/shine.png';
import shineV1 from 'assets/images/ingredients/shine-v1.png';
import siberianPineNut from 'assets/images/ingredients/siberian-pine-nut.png';
import siberianPineNutOil from 'assets/images/ingredients/siberian-pine-nut-oil.png';
import silicone from 'assets/images/ingredients/silicone.png';
import siliconeLeave from 'assets/images/ingredients/silicone-leave.png';
import siliconeLike from 'assets/images/ingredients/silicone-like.png';
import silkProteins from 'assets/images/ingredients/silk-proteins.png';
import skeletonBabassuOil from 'assets/images/ingredients/skeleton-babassu-oil.png';
import skeletonGrapeseedOil from 'assets/images/ingredients/skeleton-grapeseed-oil.png';
import skeletonMaracujaOil from 'assets/images/ingredients/skeleton-maracuja-oil.png';
import skeletonMelonOil from 'assets/images/ingredients/skeleton-melon-oil.png';
import skeletonPequiOil from 'assets/images/ingredients/skeleton-pequi-oil.png';
import skeletonPlumOil from 'assets/images/ingredients/skeleton-plum-oil.png';
import skeletonPoppyOil from 'assets/images/ingredients/skeleton-poppy-oil.png';
import skeletonSheaOil from 'assets/images/ingredients/skeleton-shea-oil.png';
import skeletonUngurahuiOil from 'assets/images/ingredients/skeleton-ungurahui-oil.png';
import smoker from 'assets/images/ingredients/smoker.png';
import smoothing from 'assets/images/ingredients/smoothing.png';
import soyAminoAcids from 'assets/images/ingredients/soy-amino-acids.png';
import soyBean from 'assets/images/ingredients/soy-bean.png';
import spirulina from 'assets/images/ingredients/spirulina.png';
import squalane from 'assets/images/ingredients/squalane.png';
import sugarBeetDerivative from 'assets/images/ingredients/sugar-beet-derivative.png';
import sunflowerOil from 'assets/images/ingredients/sunflower-oil.png';
import sunflowerSeeds from 'assets/images/ingredients/sunflower-seeds.png';
import sweetAlmond from 'assets/images/ingredients/sweet-almond.png';
import sweetAlmondOil from 'assets/images/ingredients/sweet-almond-oil.png';
import tapiocaStarch from 'assets/images/ingredients/tapioca-starch.png';
import taraGum from 'assets/images/ingredients/tara-gum.png';
import teaTreeOil from 'assets/images/ingredients/tea-tree-oil.png';
import thiamin from 'assets/images/ingredients/thiamin.png';
import tomato from 'assets/images/ingredients/tomato.png';
import tomatoOil from 'assets/images/ingredients/tomato-oil.png';
import tsubakiMelonOil from 'assets/images/ingredients/tsubaki-melon-oil.png';
import tsubakiOil from 'assets/images/ingredients/tsubaki-oil.png';
import turmeric from 'assets/images/ingredients/turmeric.png';
import ungurahui from 'assets/images/ingredients/ungurahui.png';
import ungurahuiOil from 'assets/images/ingredients/ungurahui-oil.png';
import urbanProtect from 'assets/images/ingredients/urban-protect.png';
import urbanProtectV2 from 'assets/images/ingredients/urban-protect-v2.png';
import uvProtect from 'assets/images/ingredients/uv-protect.png';
import veganCapsule from 'assets/images/ingredients/vegan-capsule.png';
import vinegar from 'assets/images/ingredients/vinegar.png';
import vitaminB6 from 'assets/images/ingredients/vitamin-b6.png';
import vitaminB12 from 'assets/images/ingredients/vitamin-b12.png';
import vitaminE from 'assets/images/ingredients/vitamin-e.png';
import vitaminF from 'assets/images/ingredients/vitamin-f.png';
import vitamineForte from 'assets/images/ingredients/vitamine-forte.png';
import volumePowder from 'assets/images/ingredients/volume-powder.png';
import watercressExtract from 'assets/images/ingredients/watercress-extract.png';
import wheat from 'assets/images/ingredients/wheat.png';
import wheatAminoAcids from 'assets/images/ingredients/wheat-amino-acids.png';
import whiteClay from 'assets/images/ingredients/white-clay.png';
import wintergreenExtract from 'assets/images/ingredients/wintergreen-extract.png';
import witchHazelExtract from 'assets/images/ingredients/witch-hazel-extract.png';
import yuccaExtract from 'assets/images/ingredients/yucca-extract.png';

const ingredientsAssets = {
  '12hexanediol.png': defaultOil,
  'abyssinian-oil.png': abyssinianOil,
  'acacia-collagen.png': acaciaCollagen,
  'acai.png': acai,
  'acai-oil.png': acaiOil,
  'acerola.png': acerola,
  'agastache.png': agastache,
  'aloe-vera.png': aloeVeraJuice,
  'aloe-vera-juice.png': aloeVeraJuice,
  'alphaglucan-oligosaccharide.png': alphaGlucanOligosaccharide,
  'alpha-glucan-oligosaccharide.png': alphaGlucanOligosaccharide,
  'amino-acid.png': aminoAcid,
  'anti-brassiness.png': antiBrassiness,
  'argan-v2.png': argan,
  'apple-cider-vinegar.png': appleCiderVinegar,
  'argan.png': arganOilV2,
  'argan-oil.png': arganOil,
  'arginine.png': arginine,
  'avocado.png': avocado,
  'avocado-oil.png': avocadoOil,
  'babassu.png': babassu,
  'babassu-oil.png': babassuOil,
  'bamboo-charcoal.png': charcoalPowder,
  'baobab-oil.png': defaultOil,
  'baobab-extract.png': baobabExtract,
  'baobab-seed-extract.png': baobabExtract,
  'barbary-fig-grapeseed-oil.png': barbaryFigGrapeseedOil,
  'barbary-fig-oil.png': barbaryFigOil,
  'bakuchiol.png': bakuchiol,
  'basic-red-51.png': basicRed51,
  'beech-oil.png': beechOil,
  'behentrimonium-chloride.png': defaultOil,
  'biome.png': biome,
  'binchotan-charcoal.png': binchotanCharcoal,
  'biotin.png': biotin,
  'birch-tree-extract.png': birchTreeExtract,
  'bitter-almond-oil.png': bitterAlmondOil,
  'black-currant-oil.png': blackCurrantOil,
  'blackcurrant-oil.png': blackcurrantOil,
  'brewers-yeast.png': brewersYeast,
  'bronze.png': bronze,
  'bush-clover.png': bushClover,
  'bush-clover-extract.png': bushClover,
  'butterfly-pea-extract.png': butterflyPeaExtract,
  'butterfly-pea-flower.png': butterflyPeaFlower,
  'butylene-glycol.png': defaultOil,
  'california-wild-grape.png': californiaWildGrape,
  'calming-soothing.png': calmingSoothing,
  'camelina-oil.png': camelinaOil,
  'cannabidiol.png': cannabidiol,
  'carob-seed-extract.png': carobSeedExtract,
  'castor.png': castor,
  'castor-oil.png': castorOil,
  'caviar-lime-extract.png': caviarLimeExtract,
  'ceramides.png': ceramides,
  'cetearyl-alcohol.png': arginine,
  'charcoal-powder.png': charcoalPowder,
  'chia-oil.png': chiaOil,
  'chicory-camelina-oil.png': chicoryCamelinaOil,
  'chicory-oil.png': chicoryOil,
  'coconut.png': coconutOil,
  'coconut-oil.png': coconutOil,
  'coconut-oil-polyglyceryl6-esters.png': coconutOil,
  'color-protect.png': colorProtect,
  'copaiba.png': copaibaOil,
  'copaiba-oil.png': copaibaOil,
  'coriander-oil.png': corianderOil,
  'cornflower.png': cornflower,
  'corn-maltodextrin.png': maltodextrin,
  'coss.png': coss,
  'cucumber.png': cucumber,
  'cucumber-oil.png': cucumberOil,
  'curling.png': curling,
  'curling-v1.png': curling,
  'dandruff.png': dandruff,
  'dandruff-old.png': dandruff,
  'dandruff-powder.png': dandruffPowder,
  'dandruff-v1.png': dandruff,
  'dandruff-v2.png': dandruff,
  'default-oil.png': defaultOil,
  'detox.png': detox,
  'detox-powder.png': detoxPowder,
  'dextrin.png': dextrin,
  'dipotassium-glycyrrhizate.png': dipotassiumGlycyrrhizate,
  'dipropylene-glycol.png': defaultOil,
  'dog-rose.png': dogRose,
  'essential-oils.png': essentialOils,
  'eucalyptus-oil.png': eucalyptusOil,
  'eucalyptus-scent.png': eucalyptusOil,
  'fermented-rice-water.png': fermentedRiceWater,
  'fermented-sugar.png': acaciaCollagen,
  'fermented-zinc.png': fermentedZinc,
  'flaxseed-extract.png': flaxSeedExtract,
  'flax-seed-extract.png': flaxSeedExtract,
  'folate.png': folate,
  'freshness-powder.png': freshnessPowder,
  'gold.png': gold,
  'grape-seed-extract.png': grapeSeedExtract,
  'grape-seed-oil.png': grapeSeedOil,
  'grapefruit-oil.png': grapefruitOil,
  'grapefruit-scent.png': grapefruitOil,
  'grapeseed-oil.png': grapeseedOil,
  'green-tea.png': greenTea,
  'green-tea-water.png': greenTea,
  'grenade.png': grenade,
  'hair-loss.png': hairLoss,
  'hair-loss-v1.png': hairLoss,
  'hair-supplements-acerola-extract.png': acerola,
  'hair-supplements-biotin.png': biotin,
  'hair-supplements-grape-seed-extract.png': californiaWildGrape,
  'hair-supplements-maltodextrin.png': maltodextrin,
  'hair-supplements-methionine.png': methionine,
  'hair-supplements-millet-seed-extract.png': millet,
  'hair-supplements-niacin.png': niacin,
  'hair-supplements-rice-protein.png': riceProtein,
  'hair-supplements-sesame-seed-extract.png': sesameSeeds,
  'hair-supplements-spirulina-extract.png': spirulina,
  'hair-supplements-thiamin.png': thiamin,
  'hair-supplements-vitamin-b6.png': vitaminB6,
  'hair-supplements-vitamin-b12.png': vitaminB12,
  'hair-supplements-wheat-oil': defaultOil,
  'hair-supplements-zinc.png': fermentedZinc,
  'hazelnut.png': hazelnut,
  'hazelnut-oil.png': hazelnutOil,
  'hc-blue-15.png': hcBlue15,
  'heat-protect.png': heatProtect,
  'heat-protect-v1.png': heatProtectV1,
  'hibiscus.png': hibiscus,
  'hibiscus-flower.png': hibiscusFlower,
  'horehound-extract.png': horehoundExtract,
  'horsetail.png': horsetail,
  'honey.png': honey,
  'hyaluronic.png': hyaluronic,
  'hyaluronic-acid.png': hyaluronicAcid,
  'hydrolyzed-glycosaminoglycans.png': hyaluronicAcid,
  'illipe-butter.png': illipeButter,
  'inca-inchi-oil.png': incaInchiOil,
  'intense-repair.png': intenseRepair,
  'intense-repair-v2.png': hyaluronicAcid,
  'inulin.png': defaultOil,
  'ivy-extract.png': ivyExtract,
  'jasmin-flower-water.png': jasminWater,
  'jasmin-water.png': jasminWater,
  'jojoba-beads.png': jojobaBeads,
  'jojoba-oil.png': jojobaOil,
  'jojoba-seed.png': jojobaOil,
  'jujube-bark-extract.png': jujubeBarkExtract,
  'kalahari-melon-oil.png': kalahariMelonOil,
  'kalahari-oil.png': kalahariOil,
  'kale-extract.png': kaleExtract,
  'kaolin.png': whiteClay,
  'karanja-oil.png': karanjaOil,
  'kombucha.png': kombucha,
  'kombucha-tea.png': kombucha,
  'lemon-butter.png': lemonButter,
  'lila.png': lila,
  'lilac-extract.png': lila,
  'maca-root.png': macaRoot,
  'maltodextrin.png': maltodextrin,
  'mango-butter.png': mangoButter,
  'mango-leaf.png': mangoLeaf,
  'mango-leaf-extract.png': mangoLeaf,
  'maracuja.png': maracuja,
  'maracuja-oil.png': maracujaOil,
  'melon-oil.png': melonOil,
  'mentha.png': mentha,
  'menthol.png': menthol,
  'methionine.png': methionine,
  'millet.png': millet,
  'modified-corn-starch.png': modifiedCornStarch,
  'myrica-wax.png': mangoButter,
  'nasturtium-extract.png': nasturtiumExtract,
  'niacin.png': niacin,
  'niacinamide.png': niacinamide,
  'nutrition.png': nutrition,
  'oat-flour.png': oatFlour,
  'oat-oil.png': oatOil,
  'okra-extract.png': defaultOil,
  'okra-extract-new.png': defaultOil,
  'pantothenic-acid.png': pantothenicAcid,
  'pea-protein.png': peaProtein,
  'peppermint-extract.png': peppermintExtract,
  'peptides.png': defaultOil,
  'pequi.png': pequi,
  'pequi-oil.png': pequiOil,
  'phytoceramides.png': defaultOil,
  'pink-clay.png': pinkClay,
  'pistachio-oil.png': pistachioOil,
  'plum.png': plum,
  'plum-oil.png': plumOil,
  'poppy.png': poppy,
  'poppy-oil.png': poppyOil,
  'porphyra-algae.png': porphyraAlgae,
  'pure-biotin.png': pureBiotin,
  'quinoa-extract.png': quinoaExtract,
  'raspberry.png': raspberry,
  'raspberry-oil.png': raspberryOil,
  'red-algae.png': redAlgae,
  'red-fungi.png': redFungi,
  'reed-extracts.png': reedExtracts,
  'riboflavin.png': riboflavin,
  'rice-bran.png': riceBran,
  'rice-bran-oil.png': riceBranOil,
  'rice-protein.png': riceProtein,
  'rice-starch.png': riceStarch,
  'rose-hip-oil.png': roseHipOil,
  'rosehip-oil.png': rosehipOil,
  'rosemary.png': rosemary,
  'sacha-inchi.png': sachaInchi,
  'sacha-inchi-oil.png': sachaInchiOil,
  'saffron.png': saffron,
  'sea-lily.png': seaLily,
  'sebum.png': sebum,
  'sebum-powder.png': sebumPowder,
  'sebum-v1.png': sebum,
  'sensitivity-powder.png': sensitivityPowder,
  'sesame-seeds.png': sesameSeeds,
  'shea-butter.png': sheaButter,
  'shea-oil.png': sheaOil,
  'shikakai-extract.png': shikakaiExtract,
  'shine.png': shine,
  'shine-v1.png': shineV1,
  'siberian-pine-nut.png': siberianPineNut,
  'siberian-pine-nut-oil.png': siberianPineNutOil,
  'silicone.png': silicone,
  'silicone-leave.png': siliconeLeave,
  'silicone-like.png': siliconeLike,
  'silicone-quaternium17.png': silicone,
  'silicone-v1.png': silicone,
  'silk-proteins.png': silkProteins,
  'skeleton-babassu-oil.png': skeletonBabassuOil,
  'skeleton-grapeseed-oil.png': skeletonGrapeseedOil,
  'skeleton-maracuja-oil.png': skeletonMaracujaOil,
  'skeleton-melon-oil.png': skeletonMelonOil,
  'skeleton-pequi-oil.png': skeletonPequiOil,
  'skeleton-plum-oil.png': skeletonPlumOil,
  'skeleton-poppy-oil.png': skeletonPoppyOil,
  'skeleton-shea-oil.png': skeletonSheaOil,
  'skeleton-ungurahui-oil.png': skeletonUngurahuiOil,
  'smoker.png': smoker,
  'smoothing.png': smoothing,
  'smoothing-v1.png': smoothing,
  'sodium-benzoate.png': arginine,
  'squalane.png': squalane,
  'sorbitol.png': arginine,
  'soy-amino-acids.png': soyAminoAcids,
  'soy-bean.png': soyBean,
  'soybean-oil.png': soyBean,
  'sphingomonas-ferment-extract.png': acaciaCollagen,
  'spirulina.png': spirulina,
  'stearyl-amidopropyl-dimethylamine.png': arginine,
  'sugar-beet-derivative.png': sugarBeetDerivative,
  'sunflower-flower.png': sunflowerOil,
  'sunflower-oil.png': sunflowerOil,
  'sunflower-seed.png': sunflowerOil,
  'sunflower-seeds.png': sunflowerSeeds,
  'sweet-almond.png': sweetAlmond,
  'sweet-almond-oil.png': sweetAlmondOil,
  'tapioca-starch.png': tapiocaStarch,
  'tara-gum.png': taraGum,
  'tea-tree-oil.png': teaTreeOil,
  'thiamin.png': thiamin,
  'tomato.png': tomato,
  'tomato-as-oil.png': tomatoOil,
  'tomato-oil.png': tomatoOil,
  'tsubaki-melon-oil.png': tsubakiMelonOil,
  'tsubaki-oil.png': tsubakiOil,
  'turmeric.png': turmeric,
  'ungurahui.png': ungurahui,
  'ungurahui-oil.png': ungurahuiOil,
  'urban-protect.png': urbanProtect,
  'urban-protect-v2.png': urbanProtectV2,
  'uv-protect.png': uvProtect,
  'vegan-capsule.png': veganCapsule,
  'vinegar.png': vinegar,
  'vitamin-b6.png': vitaminB6,
  'vitamin-b12.png': vitaminB12,
  'vitamin-c.png': arginine,
  'vitamin-e.png': vitaminE,
  'vitamin-f.png': vitaminF,
  'vitamine-forte.png': vitamineForte,
  'volume.png': modifiedCornStarch,
  'volume-powder.png': volumePowder,
  'watercress-extract.png': watercressExtract,
  'wheat.png': wheat,
  'wheat-amino-acids.png': wheatAminoAcids,
  'white-clay.png': whiteClay,
  'wintergreen-extract.png': wintergreenExtract,
  'witch-hazel-extract.png': witchHazelExtract,
  'yacon-root.png': tapiocaStarch,
  'yucca-extract.png': yuccaExtract,
  'yucca-stem-extract.png': yuccaExtract,
};

export const ingredientURL = (slug: string) => {
  /**
   * The API uses:
   * - `_` for the Consultation Posology page
   * - `-` for the Glossary page
   * Since streamlining the slugs is a big refactoring for the API and the Algo
   * We need to convert all `_` in the frontend for the moment
   */
  const streamlinedSlug = slug?.replace(/_/g, '-');
  const ingredientsAsset =
    ingredientsAssets?.[`${streamlinedSlug}.png` as keyof typeof ingredientsAssets];

  // If no image has been found in the list, we load a default oil image
  if (!ingredientsAsset) {
    Sentry.captureMessage(
      `Missing ingredient asset - assets/images/ingredients/${streamlinedSlug}.png`,
    );
    return defaultOil;
  }

  return ingredientsAsset;
};
