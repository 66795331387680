import {
  type AnyAction,
  configureStore,
  type PreloadedState,
  type ThunkAction,
} from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';

import createRootReducer, { persistedReducer } from 'rootReducer';

import errorMiddleware from 'Middleware/errorMiddleware';
import sentryMiddleware from 'Middleware/sentryMiddleware';

import getEnv, { getEnvOrThrow } from 'utils/getEnv';

import { listenerMiddleware } from 'dux/app/listenerMiddleware';
import checkoutApiSlice from 'dux/checkout/apiSlice';
import cartInitialState from 'dux/checkoutCart/initialState';
import feedbackApiSlice from 'dux/feedback/apiSlice';
import giftApiSlice from 'dux/gift/apiSlice';
import prescriptionApiSlice from 'dux/prescription/apiSlice';
import subscriptionsApiSlice from 'dux/subscriptions/apiSlice';
import userApiSlice from 'dux/user/apiSlice';

const DEBUG =
  getEnvOrThrow('REACT_APP_ENV') === 'development' &&
  getEnv('REACT_APP_REDUX_LOGGER') !== false &&
  typeof window !== 'undefined';

/*
 * INFO: RootState is defined from the rootReducer instead of store.getState in order to avoid
 * circular dependencies when typyng middlewares
 * https://redux.js.org/usage/usage-with-typescript#type-checking-middleware
 */
export type RootState = ReturnType<typeof persistedReducer>;

// Preloaded State Setup
const getPreloadedState = () => ({
  checkout: {
    cart: {
      ...cartInitialState,
    },
  },
});

// Final: Store Setups
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      /*
       * INFO: listener middlewares should go before the serializability check middlewares
       * thus the prepend call https://redux-toolkit.js.org/api/createListenerMiddleware#middleware
       */
      // WARN: as const because of https://github.com/reduxjs/redux-toolkit/issues/2432#issuecomment-1169456290
      .prepend(listenerMiddleware.middleware)
      .concat(sentryMiddleware)
      .concat(errorMiddleware)
      .concat(giftApiSlice.middleware)
      .concat(prescriptionApiSlice.middleware)
      .concat(userApiSlice.middleware)
      .concat(feedbackApiSlice.middleware)
      .concat(subscriptionsApiSlice.middleware)
      .concat(checkoutApiSlice.middleware);
    if (DEBUG) {
      middlewares.concat(logger);
    }

    return middlewares;
  },
  preloadedState: getPreloadedState(),
});

// Infer the `AppDispatch` type from the store itself
export type AppDispatch = typeof store.dispatch;
// INFO: type helper to reduce redundancy when typing synchronous thunk
export type AppThunk<RetType = void> = ThunkAction<RetType, RootState, unknown, AnyAction>;

// Recommended way of accessing redux store from cypress https://www.cypress.io/blog/2018/11/14/testing-redux-store/
if (typeof window !== 'undefined' && window.Cypress) {
  window.store = store;
}

const createStore = () => store;
export default createStore;

// For unit tests

export const testRootReducer = createRootReducer();

export const createTestStore = (preloadedState: PreloadedState<RootState>) => {
  return configureStore({
    preloadedState,
    reducer: testRootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(feedbackApiSlice.middleware).concat(userApiSlice.middleware),
  });
};
