import isEmpty from 'lodash/fp/isEmpty';
import isNil from 'lodash/fp/isNil';

import { createSelector } from 'reselect';

import * as Status from 'constants/statuses';

import * as accountCardsSelectors from 'dux/accountCards/selectors';
import * as checkoutAddressesSelectors from 'dux/checkoutAddresses/selectors';
import * as checkoutCartSelectors from 'dux/checkoutCart/selectors';
import * as checkoutPaymentSelectors from 'dux/checkoutPayment/selectors';
import { getUserPhone } from 'dux/user/selectors';

export const getCheckoutStatusWithSavedPayments = createSelector(
  checkoutAddressesSelectors.getAddressesStatus,
  checkoutCartSelectors.getCartStatus,
  accountCardsSelectors.getStatus,
  (addressesStatus, cartStatus, cardsStatus) => {
    const statuses = [addressesStatus, cartStatus, cardsStatus];

    if (statuses.includes('error')) {
      return 'error';
    }

    if (statuses.includes('loading')) {
      return 'loading';
    }

    // TODO: harmonize status string :'(
    if (statuses.every(status => status === 'success' || status === Status.SUCCESS)) {
      return 'success';
    }

    return 'idle';
  }
);

export const getHasCompletedAccountDetailsSection = createSelector(
  getUserPhone,
  userPhone => !isEmpty(userPhone)
);

export const getHasCompletedShippingAddressSection = createSelector(
  checkoutCartSelectors.getCartShippingAddress,
  shippingAddress => !isNil(shippingAddress)
);

export const getHasCompletedPaymentSection = createSelector(
  checkoutCartSelectors.getIsFreeAndCartHasNoSubscription,
  accountCardsSelectors.getPreselectedCardId,

  (isFreeAndCartHasNoSubscription, preselectedCardId) => {
    return isFreeAndCartHasNoSubscription || !isNil(preselectedCardId);
  }
);

export const getNextStepFromStepProp = createSelector(
  getHasCompletedPaymentSection,
  getHasCompletedShippingAddressSection,
  checkoutCartSelectors.getIsFreeAndCartHasNoSubscription,
  (_state, { step }) => step,
  (
    hasCompletedPaymentSection,
    hasCompletedShippingAddressSection,
    isFreeAndCartHasNoSubscription,
    step
  ) => {
    switch (step) {
      case 'account-details':
        if (!hasCompletedShippingAddressSection || isFreeAndCartHasNoSubscription) {
          return 'shipping-address';
        }
        if (hasCompletedPaymentSection || hasCompletedShippingAddressSection) {
          return 'payment';
        }
        return 'shipping-address';
      case 'shipping-address':
        if (isFreeAndCartHasNoSubscription) {
          return 'success';
        }
        return 'payment';
      case 'payment':
        return 'success';
      default:
        return null;
    }
  }
);

export const getCTAButtonLabelFromStepProp = createSelector(
  getNextStepFromStepProp,
  getHasCompletedPaymentSection,
  getHasCompletedShippingAddressSection,
  (nextStep, hasCompletedPaymentSection, hasCompletedShippingAddressSection) => {
    switch (nextStep) {
      case 'shipping-address':
        if (hasCompletedShippingAddressSection) {
          return 'Continue to payment';
        }
        return 'Continue to shipping';
      case 'payment':
        if (hasCompletedPaymentSection) {
          return 'Place my order';
        }
        return 'Continue to payment';
      case 'success':
        return 'Place my order';
      default:
        return 'Next';
    }
  }
);

export const getSelectedAddress = createSelector(
  checkoutAddressesSelectors.getAddressesData,
  checkoutCartSelectors.getCartData,
  (addresses = [], cart) => addresses?.find(({ pubkey }) => pubkey === cart?.shipping_address)
);

export const getSelectedCard = createSelector(
  (state, { cardPubkey }) => accountCardsSelectors.getCardById(state, { id: cardPubkey }),
  checkoutPaymentSelectors.getStripeCard,
  (registeredCard, stripeCard) => registeredCard || stripeCard
);

export const getIsCheckoutReadyForPayment = createSelector(
  checkoutPaymentSelectors.getIsPaymentReady,
  checkoutCartSelectors.getIsFreeAndCartHasNoSubscription,
  (isPaymentReady, isFreeAndCartHasNoSubscription) =>
    isPaymentReady || isFreeAndCartHasNoSubscription
);

export const getCTAButtonIsLoadingForCheckoutPaymentFromIsSubmittingAndCardPubkeyProp =
  createSelector(
    (_state, { isSubmitting }) => isSubmitting,
    (_state, { cardPubkey }) => cardPubkey,
    checkoutPaymentSelectors.getIsPaymentReady,
    (isSubmitting, cardPubkey, isPaymentReady) =>
      isSubmitting || (!isPaymentReady && cardPubkey === 'newCard')
  );
