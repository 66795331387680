/**
 * Forward UTM parameter to the target URL to prevent loosing tracking information
 * which can be improperly encoded.
 * @param {string | URL | Location} currentURL
 * @param {string} redirectionURL
 * @returns {URL}
 */

const otherParamsToForward = ['nbt', 'nb_placement'];

export const forwardUTMIfNeeded = (currentURL, redirectionURL = '/') => {
  const targetURL = new URL(redirectionURL);
  const hasUTM = Array.from(targetURL.searchParams.keys()).some(key => key.startsWith('utm_'));

  if (!hasUTM) {
    const sourceURL = new URL(currentURL);
    sourceURL.searchParams.forEach((value, key) => {
      if (key.startsWith('utm_') || otherParamsToForward.indexOf(key) !== -1) {
        targetURL.searchParams.append(key, value);
      }
    });
  }

  return targetURL;
};

/**
 * @param {URL} absoluteURL
 * @returns {string}
 */
export const toRelativeURL = absoluteURL =>
  absoluteURL.pathname + absoluteURL.search + absoluteURL.hash;
