import isEmpty from 'lodash/fp/isEmpty';

import * as testConfigs from './testConfigs';

// -------------------------------------------------------------------------------
// Experiment tools
// -------------------------------------------------------------------------------
// code copied directly from react-split-testing
// this library affects each test to the same seed but we want each test to be independent

// copied from https://github.com/expert-m/react-split-testing/blob/master/src/utils/getUserIdentifier.js
const generateIdentifier = () => {
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let text = '';

  for (let i = 0; i < 20; i += 1) {
    const random = Math.random();
    text += possible.charAt(Math.floor(random * possible.length));
  }

  return text;
};

// copied from https://github.com/expert-m/react-split-testing/blob/master/src/utils/weightedRandom.js
const getRandom = seed => {
  let newSeed = 0;

  if (!seed) return 0;

  for (let i = 0; i < seed.length; i += 1) {
    newSeed += seed.charCodeAt(i) * 10 ** i;
  }

  const x = Math.sin(newSeed) * 10000;
  return x - Math.floor(x);
};

// https://github.com/expert-m/react-split-testing/blob/master/src/utils/weightedRandom.js
const weightedRandom = (weights, randomSeed) => {
  // randomSeed must be a string
  if (!randomSeed) {
    return -1;
  }

  const totalWeight = weights.reduce((acc, weight) => acc + weight, 0);

  let random = getRandom(randomSeed) * totalWeight;

  if (!weights) return -1;

  for (let i = 0; i < weights.length; i += 1) {
    if (random < weights[i]) {
      return i;
    }

    random -= weights[i];
  }

  return -1;
};

const getLocalStorageKey = Test => `op_${Test.TEST_ID}`;

const getSelectedVariant = (Test, seed) => {
  const weights = Test.variants.map(v => v.weight);
  const selectedIndex = weightedRandom(weights, seed);
  return Test.variants[selectedIndex];
};

export const pruneABTestLocalStorage = () => {
  if (typeof window === 'undefined' || isEmpty(window._localStorage)) return;

  const currentTestIds = Object.values(testConfigs).map(getLocalStorageKey);

  // loop through local storage and remove unused test keys
  Object.keys(window?._localStorage).forEach(item => {
    if (item.indexOf('op_') === 0 && !currentTestIds.includes(item)) {
      window?._localStorage?.removeItem(item);
    }
  });
};

// chooses a random variant from the test and persists in local storage
export const getOrCreateTestVariant = Test => {
  // persist one seed for each experience ID
  const localStorageKey = getLocalStorageKey(Test);
  let seed = window._localStorage.getItem(localStorageKey);
  if (!seed) {
    seed = generateIdentifier();
    window._localStorage.setItem(localStorageKey, seed);
  }

  return getSelectedVariant(Test, seed);
};
