import get from 'lodash/fp/get';

import { createSelector } from 'reselect';

const getSignup = get('signup');

export const getIsInvalidCoupon = createSelector(getSignup, get('invalidCoupon'));
export const getIsLoading = createSelector(getSignup, get('isLoading'));
export const getHasError = createSelector(getSignup, get('error'));
export const getIsMagicLinkSent = createSelector(getSignup, get('magicLinkSent'));
export const getFirstname = createSelector(getSignup, get('firstname'));
