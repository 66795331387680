export const ADDRESS_VALIDATION_FIELD_ADDRESS_SIMPLIFIED = 'address_simplified';
// The following slugs are used by Google Address Validation API
const ADDRESS_VALIDATION_FIELD_AREA = 'administrative_area_level_1';
const ADDRESS_VALIDATION_FIELD_COUNTRY = 'country';
const ADDRESS_VALIDATION_FIELD_LOCALITY = 'locality';
const ADDRESS_VALIDATION_FIELD_POINT_OF_INTEREST = 'point_of_interest';
const ADDRESS_VALIDATION_FIELD_POSTAL_CODE = 'postal_code';
const ADDRESS_VALIDATION_FIELD_ROUTE = 'route';
const ADDRESS_VALIDATION_FIELD_STREET_NUMBER = 'street_number';
const ADDRESS_VALIDATION_FIELD_SUBLOCALITY = 'sublocality_level_1';
const ADDRESS_VALIDATION_FIELD_SUBPREMISE = 'subpremise';
export const ADDRESS_VALIDATION_FIELD_POSTAL_CODE_SUFFIX = 'postal_code_suffix';

// Mapping slugs and readable values for users
export const googleAddressValidationApiFields = {
  [ADDRESS_VALIDATION_FIELD_ADDRESS_SIMPLIFIED]: 'address',
  [ADDRESS_VALIDATION_FIELD_AREA]: 'administrative area',
  [ADDRESS_VALIDATION_FIELD_COUNTRY]: 'country',
  [ADDRESS_VALIDATION_FIELD_LOCALITY]: 'locality',
  [ADDRESS_VALIDATION_FIELD_POINT_OF_INTEREST]: 'point of interest',
  [ADDRESS_VALIDATION_FIELD_POSTAL_CODE]: 'postal code',
  [ADDRESS_VALIDATION_FIELD_ROUTE]: 'route',
  [ADDRESS_VALIDATION_FIELD_STREET_NUMBER]: 'street number',
  [ADDRESS_VALIDATION_FIELD_SUBLOCALITY]: 'sublocality',
  [ADDRESS_VALIDATION_FIELD_SUBPREMISE]: 'subpremise',
};

// For UX purpose, we list some of the fields that can be merged into a simplified one 'Address not found'
export const address1AdressComponents = [
  ADDRESS_VALIDATION_FIELD_POINT_OF_INTEREST,
  ADDRESS_VALIDATION_FIELD_ROUTE,
  ADDRESS_VALIDATION_FIELD_STREET_NUMBER,
  ADDRESS_VALIDATION_FIELD_SUBPREMISE,
];
